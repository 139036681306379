.base {
  @mixin container;
  font-size: 14px;

  @media (--lt-tablet) {
    width: 100%;
  }

  ol {
    list-style: none;
    overflow: hidden;
    padding: 0 8px 2px;
    margin: 4px 0;
    font-size: 13px;
    border-bottom: 1px solid var(--gray4);
    color: var(--gray6);

    @media (--gt-largephone) {
      margin: 15px 0;
    }
  }

  li {
    display: inline-block;
    margin: 2px 0;

    &::after {
      display: inline-block;
      content: '';
      height: 16px;
      width: 16px;
      background: svg-load('breadcrumbs_separator.svg') no-repeat center center;
      vertical-align: middle;
      margin: 0 0.6em;
    }

    &:first-child::after {
      margin: 0;
    }

    &:first-child::after,
    &:last-child::after {
      background: none;
    }

    a {
      color: var(--gray8);
      text-decoration: underline;
    }
  }

  i {
    --icon-size: 1.2em;
    margin: 0;
  }
}
